import { busesOption, routeTypes } from '../helper'

export const useCalculate = () => {
  const _calculateRoute = (data, result) => {
    const routes = result.routes[0]
    const routeType = data['route-type']
    const bus = busesOption.filter((bus) => bus.value === data['buses-type'])[0]
    const waitTime = data['wait-time'] ? data['wait-time'] : 0

    let distance = 0

    if (routes) {
      routes.legs.forEach((leg) => {
        distance += leg.distance.value
      })
    }
    switch (routeType) {
      case 'intercity':
        result = (
          waitTime * bus.price.waiting +
          ((distance / 1000) * 2 + 10) * bus.price.longDistance
        ).toFixed(2)
        return result
      case 'local':
        result = (
          waitTime * bus.price.waiting +
          ((distance / 1000) * 2 + 10) * bus.price.shortDistance
        ).toFixed(2)
        return result
    }
  }
  const _handleFormData = (data, result) => {
    const busType = busesOption.filter(
      (bus) => bus.value === data['buses-type']
    )[0].label
    const routeType = routeTypes.filter(
      (route) => route.value === data['route-type']
    )[0].label

    const sum = _calculateRoute(data, result)

    return {
      from: data['route-from'].label,
      to: data['route-to'].label,
      time: data['wait-time'],
      busType,
      routeType,
      sum,
    }
  }
  return {
    calculateRoute: _calculateRoute,
    handleFormData: _handleFormData,
  }
}
