export const busesOption = [
  {
    label: '18 місць для посадки',
    value: 'bus-type-1',
    price: { longDistance: 7.5, shortDistance: 7.5, waiting: 100 },
  },
  {
    label: '28/30 місць для посадки',
    value: 'bus-type-2',
    price: { longDistance: 12, shortDistance: 12, waiting: 150 },
  },
  {
    label: '38 місць для посадки',
    value: 'bus-type-3',
    price: { longDistance: 14, shortDistance: 14, waiting: 150 },
  },
  {
    label: '49/51/54 місць для посадки',
    value: 'bus-type-4',
    price: { longDistance: 16, shortDistance: 16, waiting: 200 },
  },
  {
    label: '57/59/69 місць для посадки',
    value: 'bus-type-5',
    price: { longDistance: 18, shortDistance: 18, waiting: 300 },
  },
]

export const routeTypes = [
  {
    label: 'Міжміський',
    value: 'intercity',
  },
  {
    label: 'Приміський',
    value: 'local',
  },
]
