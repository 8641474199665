import React, { useState } from 'react'

import {
  Layout,
  Map,
  SEO,
  useModalForm,
  CalculatorModalForm,
} from '@/components'

import { FormCalculate } from './components'

const center = { lat: 49.40377342712836, lng: 27.017854392535515 }

export const Calculator = ({ pageContext: { seo } }) => {
  const [openModal, setOpenModal] = useState(false)
  const [formData, setFormData] = useState(null)
  const [sum, setSum] = useState(null)
  const [directions, setDirections] = useState(null)

  const { isLoaded } = useModalForm()

  return (
    <Layout>
      <SEO
        description={seo.metaDesc}
        title={seo.title}
        meta={[
          {
            name: `keywords`,
            content: seo.focuskw,
          },
        ]}
      />
      <section className="section-first">
        <div className="container">
          <h1 className="title_h2">Підрахунок вартості</h1>
        </div>
        <section className="calculator">
          <div className="container">
            <div className="calculator__wrpr">
              {isLoaded && (
                <FormCalculate
                  setDirections={setDirections}
                  setSum={setSum}
                  setOpenModal={setOpenModal}
                  setFormData={setFormData}
                />
              )}
              <div id="map" className="calculator__map">
                {isLoaded && (
                  <Map center={center} zoom={6} directions={directions} />
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
      <CalculatorModalForm
        openModal={openModal}
        setOpenModal={setOpenModal}
        data={formData}
      />
    </Layout>
  )
}
