import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import schema from './schema'
import { busesOption, routeTypes } from '../helper'
import { Select, PlaceAutocomplete } from '@/components'
import { useCalculate } from '../hooks'

export const FormCalculate = ({ setDirections, setFormData, setOpenModal }) => {
  const [waypoints, setWaypoints] = useState([])
  const { handleFormData } = useCalculate()

  const { handleSubmit, control, errors } = useForm({
    resolver: yupResolver(schema),
  })

  const _handleAddPositionPoint = (data) => {
    setWaypoints((prev) =>
      prev.map((item) =>
        item.key === data.key ? { ...item, location: data.location } : item
      )
    )
  }

  const _handleRemovePoint = (key) => {
    if (waypoints.length === 1) {
      setWaypoints([])
    }
    setWaypoints((prev) => prev.filter((item) => item.key !== key))
  }

  const _handleAddIntermediatePoint = () => {
    setWaypoints((prev) => [...prev, { key: new Date(), location: null }])
  }

  const _handleSubmit = (data) => {
    const filterWaypoints = waypoints.filter((waypoint) => waypoint.location)
    const DirectionsService = new google.maps.DirectionsService()
    const routeOptions = {
      destination: data['route-from'].point,
      origin: data['route-to'].point,
      travelMode: 'DRIVING',
    }
    if (filterWaypoints.length > 0) {
      routeOptions.waypoints = filterWaypoints.map((waypoint) => ({
        location: waypoint.location,
      }))
    }
    DirectionsService.route(routeOptions, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        setDirections(result)
        setOpenModal(true)
        setFormData(handleFormData(data, result))
      } else {
        console.error(`error fetching directions ${result}`)
      }
    })
  }

  return (
    <div className="calculator__form">
      <div className="select-container">
        <Controller
          control={control}
          name="route-type"
          defaultValue=""
          render={({ onChange, value }) => {
            return (
              <Select
                options={routeTypes}
                placeholder="Тип маршруту"
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </div>
      <div className="select-container">
        <Controller
          control={control}
          name="buses-type"
          defaultValue=""
          render={({ onChange, value }) => {
            return (
              <Select
                options={busesOption}
                placeholder="Тип автобуса"
                onChange={onChange}
                value={value}
              />
            )
          }}
        />
      </div>
      <Controller
        control={control}
        name="route-from"
        defaultValue=""
        render={({ onChange, value }) => (
          <PlaceAutocomplete
            name="route-from"
            placeholder="Пункт відправлення"
            onChange={onChange}
          />
        )}
      />
      {waypoints.map((point, index) => (
        <PlaceAutocomplete
          key={point.key}
          name={`points${index}`}
          placeholder="Проміжний пункт"
          onChange={(value) =>
            _handleAddPositionPoint({ key: point.key, location: value.point })
          }
          remove={() => _handleRemovePoint(point.key)}
        />
      ))}
      <Controller
        control={control}
        name="route-to"
        defaultValue=""
        render={({ onChange, value }) => (
          <PlaceAutocomplete
            name="route-to"
            placeholder="Пункт прибуття"
            onChange={onChange}
          />
        )}
      />

      <div className="wait-time-wrapper">
        <Controller
          control={control}
          name="wait-time"
          defaultValue=""
          render={({ onChange, onBlur, value, name, ref }) => (
            <input
              className="input wait-time"
              type="number"
              autoComplete="off"
              placeholder="Час простою"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              input={ref}
              name={name}
            />
          )}
        />
        <div className="wait-time-unit">год.</div>
      </div>
      <button
        className="btn btn_gradient"
        onClick={handleSubmit(_handleSubmit)}
      >
        Розрахувати
      </button>
      <div
        className="calculator__add-more"
        onClick={_handleAddIntermediatePoint}
      >
        + Додати проміжний пункт
      </div>
    </div>
  )
}
