import * as yup from 'yup'

export const schema = yup.object().shape({
  'route-type': yup.string().required("Поле обов'язкове"),
  'buses-type': yup.string().required("Поле обов'язкове"),
  'route-from': yup
    .object()
    .nullable("Поле обов'язкове")
    .required("Поле обов'язкове"),
  'route-to': yup
    .object()
    .nullable("Поле обов'язкове")
    .required("Поле обов'язкове"),
  'wait-time': yup
    .number()
    .transform((v, o) => (o === '' ? null : v))
    .nullable()
    .max(500, 'Максимальний час 500 годин'),
})

export default schema
